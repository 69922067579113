import axios from 'axios'

/**
 * Pre-configured POST request to send form submissions to the AWS Lambda function
 * that handles form submissions for this site.
 */
export const API_POST = async (data, callback) => {
  const request = {
    host: 'https://5skz6wnfy6.execute-api.us-east-1.amazonaws.com/',
    method: 'POST',
    url: 'https://5skz6wnfy6.execute-api.us-east-1.amazonaws.com/production/submit',
    data,
    body: JSON.stringify(data),
    path: '/production/submit',
    headers: {
      'content-type': 'application/json',
    },
  }

  const response = await axios(request)

  callback(response)
}

export default API_POST
