import React, { useState } from 'react'

import useIntersect from '../../hooks/useIntersect'
import useInput from '../../hooks/useInput'
import { Input, TextArea } from 'components/Input'
import { Button } from 'components/Button'
import API_POST from '../../utils/API/POST'
import './Form.scss'

export const Form = ({ colorTheme, pathname = '', displayNumber }) => {
  const { value: name, bind: bindName, reset: resetName } = useInput('')
  const { value: email, bind: bindEmail, reset: resetEmail } = useInput('')
  const {
    value: phoneNumber,
    bind: bindPhoneNumber,
    reset: resetPhoneNumber,
  } = useInput('')
  const {
    value: message,
    bind: bindMessage,
    reset: resetMessage,
  } = useInput('')

  const [result, setResult] = useState({
    disabled: false,
    message: '',
    messageType: '',
  })

  const handleSubmit = (event) => {
    event.preventDefault()
    API_POST(
      {
        name,
        email,
        phoneNumber,
        pathname,
        message,
      },
      (res) => {
        if (res.status === 200) {
          setResult({
            disabled: true,
            message:
              'Your message has been sent! If you need to submit another message, please refresh the page. Thank you!',
            messageType: 'success',
          })
          resetName()
          resetEmail()
          resetPhoneNumber()
          resetMessage()
        } else {
          setResult({
            disabled: false,
            message: `There was an error processing your message. Please try again or call us at ${displayNumber}`,
            messageType: 'fail',
          })
        }
      }
    )
  }

  const [intersectionRef, entry] = useIntersect({})

  return (
    <form
      ref={intersectionRef}
      onSubmit={(event) => handleSubmit(event)}
      className={`iliad__contact-form ${
        entry.isIntersecting ? 'animation-form' : ''
      }`}
      autoComplete='off'
      data-netlify='true'>
      <h2>Contact Us</h2>
      <p>For product orders and general inquires.</p>
      <p className='iliad__form-notify'>
        Iliad Epic Grow only sells to licensed provisioning, cultivation, and
        processing centers.
      </p>
      <Input
        type='text'
        label='Name'
        name='name'
        id='contact-name'
        color={colorTheme.bodyColor}
        {...bindName}
        required
        disabled={result.disabled}
      />
      <Input
        type='email'
        label='Email Address'
        name='email'
        id='contact-email'
        {...bindEmail}
        color={colorTheme.bodyColor}
        required
        disabled={result.disabled}
      />
      <Input
        type='text'
        mask={[
          '(',
          /[1-9]/,
          /\d/,
          /\d/,
          ')',
          ' ',
          /\d/,
          /\d/,
          /\d/,
          '-',
          /\d/,
          /\d/,
          /\d/,
          /\d/,
        ]}
        pattern='[\(]\d{3}[\)]\s\d{3}[\-]\d{4}'
        label='Phone Number'
        name='phoneNumber'
        id='contact-phone-number'
        {...bindPhoneNumber}
        color={colorTheme.bodyColor}
        required
        disabled={result.disabled}
      />
      <TextArea
        label='Message...'
        name='subject'
        id='contact-message'
        {...bindMessage}
        required
        disabled={result.disabled}
      />
      {!result.disabled && (
        <Button
          colorTheme={colorTheme}
          buttonText='Submit'
          type='submit'
          disabled={result.disabled}>
          Submit
        </Button>
      )}
      {result.message && (
        <span
          className={`iliad__form-message small iliad__form-message-${result.messageType}`}>
          {result.message}
        </span>
      )}
    </form>
  )
}

export default Form
